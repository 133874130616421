import { Facebook, LinkedIn } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";

export default function AboutUs() {
  return (
    <Box
      sx={{
        pl: 3,
        pr: 3,
      }}
    >
      <Divider />
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="caption" display="block" gutterBottom>
          Copyright © 2022 Guest World.
        </Typography>

        <Box
          sx={{
            display: "flex",
          }}
        >
          <IconButton>
            <LinkedIn />
          </IconButton>
          <IconButton>
            <Facebook />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
