import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const AboutUs = lazy(() => import("../pages/About"));
const WhoWeArea = lazy(() => import("../pages/WhoWeAre"));
const Home = lazy(() => import("../pages/Home"));

export default function RouterOutlet() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/who-we-are"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <WhoWeArea />
          </Suspense>
        }
      />
      <Route
        path="about"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <AboutUs />
          </Suspense>
        }
      />
    </Routes>
  );
}
