import {
  Box,
  CssBaseline,
  PaletteMode, Theme,
  ThemeProvider
} from "@mui/material";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Container from "./components/Container";
import Footer from "./components/Footer";
import Header from "./components/Header";
import RouterOutlet from "./routes/RouterOutlet";
import { brandingDarkTheme, brandingLightTheme } from "./theme/Theme";

const themes: Record<PaletteMode, Theme> = {
  dark: brandingDarkTheme,
  light: brandingLightTheme,
};

function App() {
  const [themeMode, setThemeMode] = useState<PaletteMode>("light");

  return (
    <ThemeProvider theme={themes[themeMode]}>
      <CssBaseline />
      <BrowserRouter>
        <Container>
          <Header mode={themeMode} onChangeThemeClick={setThemeMode} />
          <Box
            style={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <RouterOutlet />
          </Box>
          <Footer />
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
