import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

export default function ContainerPage({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      style={{
        padding: 0,
        paddingTop: 56,
        display: "flex",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
}
